.loading_overlay{
    backdrop-filter: blur(20px);
}

/* .skeleton-ui {
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }
  
  .loading-gif {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  } */